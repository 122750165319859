@font-face {
    font-family: 'Circular XX';
    src: local('Circular-regular'), url(../fonts/CircularXX-Regular.woff2) format('woff2');
}

html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

body {
    font: normal normal normal 14px/18px Circular XX;
}

.z-10 {
    z-index: 1000000 !important;
}

.editor-wrapp h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: revert;
    font-weight: revert;
}

.header a {
    padding-right: 34px;
    color: #000;
    font: normal normal normal 14px/18px Circular XX;
    transition: all 0.3s;
}

.header .active {
    color: black;
    font-weight: 500;
}

header hr {
    border-color: black;
}

.production-seperator::after {
    background-color: #e6e6e6 !important;
}

.status-select .dropdown-97:focus::after {
    border: none !important;
    outline: none !important;
}

.status-select .dropdown-97:focus-visible::after {
    border: none !important;
    outline: none !important;
}

.dropdown-97:active .ms-Dropdown-title {
    border-color: none !important;
}

.dropdown-116:active .ms-Dropdown-title {
    border: none !important;
}

.status-select,
.status-select span,
.status-select .dropdown-97 {
    border-radius: 90px;
    width: 150px;
    outline: none;
    border: none;
}

tr th {
    color: #959595 !important;
}

.border-bottom {
    border-bottom: 1px solid #dbdbdb !important;
}

.chip .el {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 3px 8px;
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.t-row .css-qc6sy-singleValue {
    color: white !important;
}

.t-row .css-tlfecz-indicatorContainer {
    color: white !important;
}

.t-row .css-14el2xx-placeholder {
    color: white !important;
}

.t-row .css-1pndypt-Input {
    color: white !important;
}

.css-319lph-ValueContainer {
    margin-top: -2px;
}

.filter label {
    font: normal normal normal 14px/18px Circular XX;
}

.filter .ms-TextField-fieldGroup {
    height: 40px;
    width: 11vw;
}

.filter .ms-Dropdown {
    height: 40px;
    width: 11vw;
}

.production_follow_table {
    font: normal normal normal 15px/19px Circular XX;
}

.production_follow_table .parent-row .MuiTableCell-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.production_follow_table_dashboard {
    font: normal normal normal 15px/19px Circular XX;
    width: 98% !important;
}
.production_follow_table tbody td {
    font: normal normal normal 15px/19px Circular XX !important;
}

.production_follow_right_part {
    font: normal normal normal 26px/33px Circular XX;
}

.production_follow_right_part h4 {
    font: normal normal bold 22px/28px Circular XX;
}

.font_16_20 {
    font-size: 18px;
}

.ms-Dropdown:focus .ms-Dropdown-caretDown {
    color: unset;
}

.ms-Dropdown:focus .ms-Dropdown-titleIsPlaceHolder {
    color: unset;
}

.ms-Dropdown:focus .ms-Dropdown-title {
    color: unset;
}

.ms-Dropdown:active .ms-Dropdown-titleIsPlaceHolder {
    color: unset;
}

.ms-Dropdown:active .ms-Dropdown-title {
    color: unset;
}
.ms-Dropdown:hover .ms-Dropdown-caretDown {
    color: unset;
}

.ms-Dropdown:hover .ms-Dropdown-titleIsPlaceHolder {
    color: unset;
}

.ms-Dropdown:hover .ms-Dropdown-title {
    color: unset;
}

.header .active {
    font-weight: 700;
}

.rdrDefinedRangesWrapper {
    width: 100% !important;
    border: none !important;
    font: normal normal normal 16px/20px Circular XX !important;
}

.rdrStaticRanges {
    flex-direction: unset !important;
}

.rdrStaticRanges :first-child {
    border-radius: 90px 0 0 90px !important;
}

.rdrStaticRange {
    border: 1px solid #e6e6e6 !important;
}
.rdrStaticRanges :not(:first-child) :hover {
    border-radius: 0 !important;
}

.rdrStaticRangeSelected {
    color: white !important;
    background-color: black !important;
    border: 1px solid black !important;
}

.rdrStaticRangeSelected :hover {
    color: white !important;
    background-color: black !important;
}

.rdrDateDisplayWrapper {
    background-color: white !important;
    border: 1px solid #e6e6e6 !important;
    border-radius: 0 90px 90px 0 !important;
    width: 200px !important;
}

.rdrDateInput {
    border: none !important;
    box-shadow: none !important;
}

.rdrDateDisplay {
    padding: 4px !important;
    margin: 0 !important;
}

.rdrStaticRangeLabel {
    padding: 10px 40px !important;
}

.rdrCalendarWrapper {
    position: relative !important;
}

.rdrMonthAndYearWrapper {
    position: absolute !important;
    top: 45px !important;
    background-color: white !important;
    z-index: 50;
}

.rdrMonths {
    position: absolute !important;
    top: 95px !important;
    background-color: white !important;
    left: -25px !important;
    z-index: 1 !important;
    border: 1px solid #e6e6e6;
}

.rdrMonthAndYearPickers {
    display: none !important;
}

.rdrMonthAndYearPickers:not(:only-child) {
    display: flex !important;
}

.rdrDefinedRangesWrapper {
    display: flex !important;
}

.rdrCalendarWrapper {
    position: relative !important;
}

.is-disabled :first-child {
    background-color: rgb(243, 242, 241) !important;
    color: rgb(96, 94, 92) !important;
}

.wrapperClassName {
    border: 1px solid #e6e6e6;
    border-top: none;
}

.editorClassName {
    padding: 15px;
}

.replyClassName {
    padding: 5px;
}

.center_hor_ver {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.toolbarClassName {
    background-color: #f5f5f5 !important;
}

.rdw-option-wrapper {
    background-color: #f5f5f5 !important;
}

.z-index-full {
    z-index: 100 !important;
}

.text-end {
    text-align: end;
}

.file-info-text {
    font: var(--unnamed-font-style-normal) normal bold 16px/20px
        var(--unnamed-font-family-circular-xx);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 16px/20px Circular XX;
    letter-spacing: 0px;
    color: #5d5d5d;
}

.file-info-heading {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) 16px/20px
        var(--unnamed-font-family-circular-xx);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal medium 16px/20px Circular XX;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.Toastify__toast--success {
    background-color: #376b9b !important;
    color: white !important;
}

.Toastify__toast--error {
    background-color: #752727 !important;
    color: white !important;
}

.Toastify__toast {
    font: normal normal normal 15px/19px Circular XX !important;
}

.Toastify__progress-bar--success {
    background-color: #8ab3d9 !important;
}

.help::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(67, 64, 248, 0.3);
    background-color: #dfd9d9;
    height: 100px !important;
    /* margin-left: 260px; */
}

.help::-webkit-scrollbar {
    width: 0px !important;
    background-color: #f5f5f5;
    height: 16px !important;
    margin-left: 100px;
}

.help::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(221, 34, 34, 0.3);
    background-color: rgb(0, 0, 0);
    height: 200px !important;
    width: 0px !important;
    margin-left: 100px;
    min-height: 200px;
    /* background-color: transparent; */
    /* border-top: 20px solid black; */
}

.shadowy {
    box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.edit-pre-modal {
    font: normal normal normal 14px/18px Circular XX;
}

.edit-pre-modal .header-99 b {
    font: normal normal normal 24px/31px Circular XX;
}

.edit-pre-modal .heading {
    font: normal normal bold 16px/20px Circular XX;
}

.infinite-scroll-component {
    height: 710px !important;
}

.survey-item h1 {
    font: normal normal bold 28px/28px Circular XX;
    color: #aaaaaa;
    margin: 20px 0;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    font-weight: bold;
}

.survey-item p {
    cursor: not-allowed;
    font: normal normal normal 24px/28px Circular XX;
    color: #aaaaaa;
    padding-bottom: 7px;
    padding-top: 7px;
    margin-left: 30px !important;
}

.block-act {
    width: 10px;
    height: 40px;
    background-color: #f8f8f8;
    position: absolute;
    margin-top: 10px;
}

.survey-main p {
    font: normal normal normal 24px/28px Circular XX;
    color: #aaaaaa;
}

.survey-main h3 {
    text-align: left;
    font: normal normal bold 26px/35px Circular XX;
    color: #000000;
    margin: 15px 0;
}
.subQuestion {
    text-align: left;
    color: #000000;
    margin: 15px 0;
    font: normal normal normal 24px/40px Circular XX;
}

.survey-main:first-child p {
    margin-top: 1px !important;
}

.survey-main span {
    font: normal normal normal 24px/40px Circular XX;
    font-weight: 100 !important;
}

.survey-main text {
    font: normal normal normal 24px/28px Circular XX;
    margin: 15px 0;
    display: inline-block;
}

.previous-button {
    font: normal normal normal 23px/28px Circular XX;
}

.continue-button {
    font: normal normal normal 24px/34px Circular XX;
    letter-spacing: 0px;
    color: #ffffff;
    background-color: #000;
    padding: 30px 40px 30px 30px;
}

.ratio-input label {
    font-size: 23px;
    color: #aaaaaa;
    margin-bottom: 10px;
}

.ratio-input input {
    padding: 10px;
}

._loading_overlay_wrapper--active {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.infinite-scroll-component__outerdiv .infinite-scroll-component {
    height: calc(100vh - 280px) !important;
    overflow: auto;
}

.MuiTooltip-popper {
    z-index: 9999999 !important;
}